// JsFromRoutes CacheKey df0e4ff06b156653c50c1f764039878a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/companies/:company_id/assistant/sessions'),
  show: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/assistant/sessions/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/w/:workspace_slug/companies/:company_id/assistant/sessions/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/w/:workspace_slug/companies/:company_id/assistant/sessions/:id'),
}
