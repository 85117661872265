// JsFromRoutes CacheKey 9fe627abf91d78390515a94bc232a04a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/assistant/tasks/:type/new'),
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/companies/:company_id/assistant/tasks'),
  show: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/assistant/tasks/:id'),
}
