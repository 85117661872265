// JsFromRoutes CacheKey bdd99bf7d100babf55997e314f9b567f
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  runs: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/autopilot/:id/runs'),
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/companies/:company_id/autopilot'),
  new: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/autopilot/new'),
  show: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/autopilot/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/w/:workspace_slug/companies/:company_id/autopilot/:id'),
}
