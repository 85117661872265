import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";

import { cn } from "@/lib/utils";

const TooltipProvider = TooltipPrimitive.Provider;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipArrow = TooltipPrimitive.Arrow;

const animation =
  "animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2";

export interface TooltipProps extends React.PropsWithChildren {
  content: React.ReactNode;
}

const Tooltip = ({ children, content }: TooltipProps) => (
  <TooltipPrimitive.Root>
    <TooltipTrigger asChild>{children}</TooltipTrigger>
    <TooltipPrimitive.Portal>
      <TooltipContent>
        {content}
        <TooltipArrow className={cn("fill-slate-900 -mt-0.5")} />
      </TooltipContent>
    </TooltipPrimitive.Portal>
  </TooltipPrimitive.Root>
);

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      "z-50 max-w-sm overflow-hidden rounded-sm border bg-slate-900 px-3 py-1.5 text-sm text-slate-100 shadow-md",
      animation,
      className,
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger };
