import NavToggle from "@/components/nav-toggle";
import { Button, buttonVariants } from "@/components/ui/button";
import Icon from "@/components/ui/icon";
import { Separator } from "@/components/ui/separator";
import useSidebar from "@/lib/hooks/use-sidebar";
import useWindowSize from "@/lib/hooks/use-window-size";
import { cn } from "@/lib/utils";
import { isAdmin } from "@/lib/utils/session-utils";
import { Link, usePage } from "@moraki/inertia-react";
import { ReactNode, useEffect } from "react";
import { Tab } from "./nav/tabs";
import { NavTheme, attrWithFallback, defaultTheme } from "./nav/themes";

interface NavProps {
  tabs: Tab[];
  theme?: NavTheme;
  header?: ReactNode;
  footer?: ReactNode;
}

export default function Nav({
  tabs,
  theme = defaultTheme,
  footer = <></>,
  header = <></>,
}: NavProps) {
  const { isMobile } = useWindowSize();
  const { sidebarShown, setSidebarShown } = useSidebar();
  const { url } = usePage();

  useEffect(() => {
    // hide sidebar on path change for mobile
    if (isMobile) setSidebarShown(false);
  }, [url, isMobile, setSidebarShown]);

  return (
    <>
      <NavToggle
        className={cn(
          "left-4 sm:left-5 top-4 sm:top-6 fixed z-30 hidden",
          !sidebarShown && "flex",
          isAdmin() && "text-white",
        )}
      />
      {isMobile && sidebarShown && (
        <div
          className="fixed w-full h-full backdrop-blur-[6px] bg-white/30 z-20"
          onClick={() => setSidebarShown(false)}
        />
      )}
      <div
        className={cn(
          "transform overflow-auto",
          sidebarShown && "translate-x-0 w-sidebar",
          !sidebarShown && "-translate-x-full",
          "fixed z-[30] flex h-full flex-col justify-between p-2 pt-5 sm:p-6 sm:pt-5 transition-all shadow-sm border-r sm:shadow-none sm:border-none",
          attrWithFallback(theme, ["main", "className"]),
        )}
        id="sidebar"
      >
        <div className="grid gap-4 sm:gap-6">
          <div className="flex items-center pl-1">
            <div className="flex flex-grow items-center">
              <Link
                href={attrWithFallback(theme, ["header", "logo", "href"])}
                className="inline-block hover:opacity-50"
              >
                <h1 className="font-newOrder text-primary font-semibold">
                  moraki
                </h1>
              </Link>
            </div>
            <Button
              onClick={() => setSidebarShown(false)}
              size="icon"
              className={attrWithFallback(theme, [
                "header",
                "chevron",
                "className",
              ])}
              variant={attrWithFallback(theme, [
                "header",
                "chevron",
                "variant",
              ])}
            >
              <Icon
                name="chevron-left"
                size={isMobile ? 24 : 16}
                color={attrWithFallback(theme, ["header", "chevron", "color"])}
              />
            </Button>
          </div>
          {header}
          <div className="grid gap-2">
            {!isAdmin() && (
              <Separator
                orientation="horizontal"
                className={cn(
                  "my-2",
                  attrWithFallback(theme, ["tabs", "separator", "className"]),
                )}
              />
            )}
            {tabs.map((tab, idx) =>
              tab ? (
                <Link
                  key={`tab-${idx}`}
                  href={tab.href}
                  className={cn(
                    buttonVariants({
                      variant: attrWithFallback(theme, [
                        "tabs",
                        "link",
                        "variant",
                      ]),
                      size: "sm",
                    }),
                    "justify-start gap-2",
                    attrWithFallback(theme, ["tabs", "link", "className"]),
                    tab.isActive &&
                      attrWithFallback(theme, [
                        "tabs",
                        "link",
                        "activeClassName",
                      ]),
                  )}
                >
                  <Icon size={24} name={tab.icon} />
                  <span className="text-md font-medium sm:text-sm flex">
                    {tab.name}
                  </span>
                </Link>
              ) : (
                <Separator
                  key={`separator-${idx}`}
                  orientation="horizontal"
                  className={cn(
                    "my-2",
                    attrWithFallback(theme, ["tabs", "separator", "className"]),
                  )}
                />
              ),
            )}
          </div>
        </div>
        {footer}
      </div>
    </>
  );
}
