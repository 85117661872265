import { cn } from "@/lib/utils";
import { Link as InertiaLink, InertiaLinkProps } from "@moraki/inertia-react";
import { forwardRef } from "react";
import { ButtonProps, buttonVariants } from "./button";

interface LinkProps extends InertiaLinkProps {
  variant?: ButtonProps["variant"];
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  if (props.target === "_blank") {
    return (
      <a
        ref={ref}
        href={props.href}
        className={cn(
          buttonVariants({ variant: props.variant || "link" }),
          props.className,
        )}
        target={props.target}
      >
        {props.children}
      </a>
    );
  }

  return (
    <InertiaLink
      {...props}
      ref={ref}
      className={cn(
        buttonVariants({ variant: props.variant || "link" }),
        props.className,
      )}
    />
  );
});

Link.displayName = "Link";

export default Link;
