// JsFromRoutes CacheKey 36581f41fc1674b21aab46a8858c01a6
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/firm/documents'),
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/firm/documents'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/w/:workspace_slug/firm/documents/:id'),
}
