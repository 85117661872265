// JsFromRoutes CacheKey 250976c6815e94a5bc9ff1bdebb5909d
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/firm/new'),
  show: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/firm'),
  update: /* #__PURE__ */ definePathHelper('patch', '/w/:workspace_slug/firm'),
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/firm'),
}
