import {
  members,
  otpApp,
  password,
  profile,
  security,
  workspaces,
} from "@/api";
import LogoutButton from "@/components/logout-button";
import AvatarWithFallback from "@/components/ui/avatar-with-fallback";
import Icon from "@/components/ui/icon";
import Link from "@/components/ui/link";
import useAuth from "@/lib/hooks/use-auth";
import useCurrentWorkspace from "@/lib/hooks/use-current-workspace";
import { cn } from "@/lib/utils";
import { t } from "i18next";

export default function Profile() {
  const auth = useAuth();
  const workspace = useCurrentWorkspace();
  const isSettingsActive = [
    workspaces.edit,
    otpApp.new,
    password.edit,
    security.show,
    members.index,
  ].some((x) => x.isCurrent());

  return (
    <div className="flex flex-col w-full justify-center gap-2">
      <Link
        variant="ghost"
        className={cn(
          "gap-4 flex-grow items-center justify-start text-sm font-medium text-secondary-foreground hover:bg-inherit hover:text-primary",
          isSettingsActive && "bg-moraki-background hover:bg-moraki-background",
        )}
        href={workspaces.edit.path(workspace)}
      >
        <Icon name="settings" size={24} />
        <div className="truncate">{t("settings.title")}</div>
      </Link>

      <Link
        variant="ghost"
        className={cn(
          "gap-4 flex-grow items-center justify-start text-sm font-medium text-secondary-foreground hover:bg-inherit hover:text-primary",
          profile.edit.isCurrent() &&
            "bg-moraki-background hover:bg-moraki-background",
        )}
        href={profile.edit.path(workspace)}
      >
        <AvatarWithFallback
          avatarable={auth}
          size="sm"
          className="cursor-pointer"
        />
        <div className="truncate">{auth.name}</div>
      </Link>
      <LogoutButton>{t("logout")}</LogoutButton>
    </div>
  );
}
